import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { path, isNil } from 'ramda'

import CardLayout from 'components/CardLayout/CardLayout'
import { formatDate, concatAddress, formatPhone } from 'utils'
import Table from 'components/Table'
import FieldsBloc from 'components/FieldsBloc'
import CollapseRow from 'components/CollapseRow/CollapseRow'
import { formatCurrency } from 'utils/currency'
import Field from '../../components/Field'
import Meteo from 'components/Meteo/Meteo'

const Claim = ({
  t,
  match: {
    params: { id: wan },
  },
  ClaimStore: { claim: clm, retrieveMeteo, loading },
  MissionStore,
  UserStore: { isExpert, hasContract },
}) => {
  if (loading) return null

  const claim = isExpert ? MissionStore.cfa.cfi : clm
  const previousClaimTable = {
    fields: ['previousClaimId', 'previousDisasterDate', 'previousPaidIndemnity'],
    values: path(['claimInformation', 'previousClaim'], claim) || [],
    headers: [
      t('mission.claim.id'),
      t('mission.claim.previousDisasterDate'),
      t('mission.claim.guarantee'),
    ],
    noValue: t('common.notAvailable'),
  }

  const claimAmount = path(['claimInformation', 'claimAmount', 'amount'], claim)

  const coverageValue =
    path(['claimInformation', 'coverage', 'value'], claim) +
    (path(['claimInformation', 'eventType', 'value'], claim)
      ? ' (' + path(['claimInformation', 'eventType', 'value'], claim) + ')'
      : '')

  return (
    <CardLayout className="claim">
      <Field
        name={t('mission.claim.lossDate')}
        value={formatDate(path(['claimInformation', 'dateOfLoss'], claim))}
      />

      <Meteo hasContract={hasContract} retrieveMeteo={() => retrieveMeteo(wan, isExpert)} />

      <Field
        name={t('mission.claim.id')}
        value={path(['claimInformation', 'insurerClaimId'], claim)}
      ></Field>
      <Field
        name={t('mission.claim.guarantee')}
        value={`${(
          path(['contract', 'guarantees[0]', 'guarantee', 'value'], claim) ||
          path(['contract', 'guarantees[0]', 'guaranteeStringName'], claim) ||
          ''
        ).trim()} 
                ${
                  (
                    path(['contract', 'guarantees[0]', 'guarantee', 'value'], claim) ||
                    path(['contract', 'guarantees[0]', 'guaranteeStringName'], claim) ||
                    ''
                  ).trim() !== ''
                    ? '| '
                    : ''
                }${coverageValue}`}
      />
      <Field
        name={t('mission.claim.startingOriginDescription')}
        value={path(['claimInformation', 'startingOriginDescription'], claim)}
      ></Field>
      <FieldsBloc
        noTopDivider
        fields={[
          {
            name: t('mission.claim.amount'),
            value:
              claimAmount !== 0 && !isNil(claimAmount)
                ? formatCurrency(
                    claimAmount,
                    path(['claimInformation', 'claimAmount', 'currency'], claim),
                  )
                : null,
          },
          {
            name: t('mission.claim.startingPoint'),
            value: path(['claimInformation', 'declaredStartPoint', 'value'], claim),
          },
          {
            name: t('mission.claim.observedDammage'),
            value: path(['claimInformation', 'observedDammage'], claim),
          },
          {
            name: t('mission.claim.observations'),
            value: path(['claimInformation', 'observations'], claim),
          },
          {
            name: t('mission.claim.reclamation'),
            value: path(['contract', 'riskDescription', 'reclamation'], claim),
          },
          {
            name: t('mission.claim.reclamationToBeSpecified'),
            value: path(['claimInformation', 'claimTypeKey'], claim),
          },
          {
            name: t('mission.claim.darvaComments'),
            value: path(['contract', 'riskDescription', 'comment'], claim),
          },
        ]}
      />
      <Table
        title={t('mission.claim.previousClaimTitle')}
        {...previousClaimTable}
        classNames="mx-4"
      />

      <div className="divider" />
      <div className="row">
        <div className="font-weight-bold">{t('mission.claim.managerIdentificationTitle')}</div>
      </div>
      <CollapseRow
        rowLabel={t('mission.claim.name')}
        rowValue={`${path(['contract', 'salesAgent', 'firstName'], claim) || ''} ${path(
          ['contract', 'salesAgent', 'lastName'],
          claim,
        ) || ''}`}
        rows={[
          {
            key: t('mission.contact.address'),
            val: concatAddress(path(['contract', 'salesAgent', 'address'], claim)) || '-',
          },
          {
            key: t('mission.contact.phoneNumber'),
            val: formatPhone(path(['contract', 'salesAgent', 'phoneNumber'], claim)) || '-',
          },
          {
            key: t('mission.contact.email'),
            val: path(['contract', 'salesAgent', 'email'], claim) || '-',
          },
          {
            key: t('mission.claim.service'),
            val: path([''], claim) || '-', //TODO agregar path del backend cuando se cree
          },
        ]}
      />
    </CardLayout>
  )
}

export default withTranslation()(inject('ClaimStore', 'UserStore', 'MissionStore')(observer(Claim)))
