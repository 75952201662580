import { observable, action, computed, runInAction, decorate } from 'mobx'
import i18next from 'i18next'

import AlertCtrl from 'stores/Common/view/AlertCtrl'
import { getDataLocal, setDataLocal } from 'stores/localStorage'
import PartyInvolved from 'stores/Common/domain/PartyInvolved'
import PartyInvolvedStore from 'stores/Common/domain/PartyInvolvedStore'
import PartyInvolvedDBStore from 'stores/Parameters/PartyInvolvedDBStore'
import UserStore from 'stores/Common/domain/UserStore'
import { deletePartyInvolved } from 'services/partyInvolved'
import MissionStore from 'stores/Mission/domain/MissionStore'
import ClaimStore from 'stores/Claim/domain/ClaimStore'

const PartyInvolvedMissionCtrl = decorate(
  class {
    get showDelete() {
      return !MissionStore.isMissionClosed
    }
    url = '/claimFiles/v1/claimFileAssessor'
    get showActions() {
      return !MissionStore.isMissionClosed
    }
  },
  {
    showActions: computed,
  },
)

const PartyInvolvedManagerClaimCtrl = decorate(
  class {
    get showDelete() {
      return !MissionStore.isMissionClosed
    }
    url = '/claimFiles/v1/claimFileManager'
    get showActions() {
      return !MissionStore.isMissionClosed
    }
  },
  {
    showActions: computed,
  },
)

const PartyInvolvedClaimCtrl = decorate(
  class {
    canSelectCreateMission = false

    get showDelete() {
      return !ClaimStore.isClaimClosed
    }
    url = '/claimFiles/v1'

    get showActions() {
      return !ClaimStore.isClaimClosed
    }

    setSelectCreateMission(bool) {
      this.canSelectCreateMission = bool
    }
  },
  {
    canSelectCreateMission: observable,
    setSelectCreateMission: action,
    showActions: computed,
  },
)

class PartyInvolvedParametersCtrl {
  showDelete = true
  url = `/customerInvolvedParty/v1/${UserStore.customer.id}`
  showActions = true
}

class PartyInvolvedCtrl {
  readOnly = false
  showModalForm = false
  party = null
  role = null
  ctrl = null
  saving = false
  displayList = false
  pageContext = 'common'

  constructor() {
    const partyInvolvedView = getDataLocal('partyInvolvedView')
    if (partyInvolvedView) this.displayList = partyInvolvedView
    else setDataLocal('partyInvolvedView', false)

    this.party = new PartyInvolved()
  }

  setDefaultCtrl() {
    this.ctrl = UserStore.isExpert
      ? new PartyInvolvedMissionCtrl()
      : UserStore.isClaimManager
      ? new PartyInvolvedManagerClaimCtrl()
      : new PartyInvolvedClaimCtrl()
  }

  setParametersCtrl() {
    this.ctrl = new PartyInvolvedParametersCtrl()
  }

  showForm = (party, restoreData, role) => {
    this.showModalForm = !this.showModalForm
    if (party) {
      this.party = party
    } else {
      if (restoreData && this.party.id) this.party.restoreOriginalData()
      this.party = new PartyInvolved()
    }
    if (role) {
      this.role = role
    } else {
      this.role = null
    }
  }

  async savePartyInvolved(wan) {
    this.saving = true
    const url = `${this.ctrl.url}${wan ? `/${wan}/involvedParties` : ''}`

    try {
      const res = await this.party.save(url)
      runInAction(() => {
        this.party.setProperty('id', res.data.id)
        AlertCtrl.alert('success', i18next.t('partyInvolved.saveSuccess'))
        this.pageContext === 'common'
          ? PartyInvolvedStore.addParty(this.party)
          : PartyInvolvedDBStore.addParty(this.party)
        this.showForm(null, false)
        this.saving = false
      })
    } catch (err) {
      console.warn(err)
      runInAction(() => {
        this.saving = false
      })
    }
  }

  async deletePI({ wan, partyInvolvedId }) {
    const url = `${this.ctrl.url}${wan ? `/${wan}/involvedParties` : ''}`

    try {
      await deletePartyInvolved(`${url}/${partyInvolvedId}`)
      runInAction(() => {
        AlertCtrl.alert('success', i18next.t('partyInvolved.deleteSuccess'))
        this.pageContext === 'common'
          ? PartyInvolvedStore.deleteParty(partyInvolvedId)
          : PartyInvolvedDBStore.deleteParty(partyInvolvedId)
      })
    } catch (err) {
      console.warn(err)
    }
  }

  setPartyInvolved(partyInvolvedId) {
    const partyInvolved = PartyInvolvedDBStore.partyInvolvedDB.find(
      partyInvolved => partyInvolvedId === partyInvolved.id,
    )

    if (partyInvolved) {
      this.party = new PartyInvolved({
        firstName: partyInvolved.firstName,
        lastName: partyInvolved.lastName,
        additionalInformation: partyInvolved.additionalInformation,
        civility: partyInvolved.civility,
        companyEmailAddress: partyInvolved.companyEmailAddress,
        companyFax: partyInvolved.companyFax,
        companyName: partyInvolved.companyName,
        companyPhoneNumber: partyInvolved.companyPhoneNumber,
        emailAddress: partyInvolved.emailAddress,
        insuredBy: partyInvolved.insuredBy,
        otherInsuredBy: partyInvolved.otherInsuredBy,
        associatedReference: partyInvolved.associatedReference,
        mobilePhone: partyInvolved.mobilePhone,
        phoneCode: partyInvolved.phoneCode,
        phoneNumber: partyInvolved.phoneNumber,
        attendance: partyInvolved.attendance,
        type: partyInvolved.type,
        country: partyInvolved.country,
        address: {
          streetNumber: partyInvolved.streetNumber,
          addressLine1: partyInvolved.addressLine1,
          addressLine2: partyInvolved.addressLine2,
          addressLine3: partyInvolved.addressLine3,
          city: partyInvolved.city,
          zipCode: partyInvolved.zipCode,
        },
        thirdPartyRole: {
          key: partyInvolved.thirdPartyKey,
          name: partyInvolved.thirdPartyName,
          type: partyInvolved.thirdPartyType,
        },
        optOut: partyInvolved.optOut,
        involvedPartyResponsability: {
          key: partyInvolved.involvedPartyResponsabilityKey,
          name: partyInvolved.involvedPartyResponsabilityName,
        },
      })
    } else
      console.warn('Something went wrong with the selection of party invovled (cannot find id)')
  }

  setDisplayList(bool) {
    setDataLocal('partyInvolvedView', bool)
    this.displayList = bool
  }

  setProperty(key, value) {
    this[key] = value
  }
}

const DecoratedPartyInvolvedCtrl = decorate(PartyInvolvedCtrl, {
  readOnly: observable,
  showModalForm: observable,
  party: observable,
  role: observable,
  ctrl: observable,
  saving: observable,
  displayList: observable,
  pageContext: observable,

  setProperty: action,
  setDisplayList: action,
  setPartyInvolved: action,
  deletePI: action,
  savePartyInvolved: action,
  showForm: action,
  setParametersCtrl: action,
  setDefaultCtrl: action,
})

export default new DecoratedPartyInvolvedCtrl()
