import React from 'react'
import { inject, observer } from 'mobx-react'
import { Switch, Route, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import classNames from 'classnames'

import './Claim.css'
import Loader from 'components/Loader/Loader'
import PageLayout from 'components/PageLayout/PageLayout'
import ProtectedRoute from 'components/ProtectedRoute'
import SideNav from 'components/SideNav/SideNav'
import PartyInvolved from 'pages/PartyInvolved/PartyInvolved'
import SupportingDocumentsPage from 'pages/SupportingDocuments/SupportingDocumentsPage'
import NotFound from 'pages/NotFound/NotFound'
import StatusSteps from 'components/StatusSteps/StatusSteps'
import Qualification from 'pages/Claim/Pages/Qualification/Qualification'
import Planner from 'pages/Claim/Pages/Planner/Planner'
import Overrun from 'pages/Claim/Pages/Overrun/Overrun'
import Events from 'pages/Events/Events'
import CurrentMission from 'pages/Claim/Pages/CurrentMission/CurrentMission'
import CurrentMissionBaloise from 'pages/Claim/Pages/CurrentMission/Baloise/CurrentMissionBaloise'
import Messaging from 'pages/Messaging'
import MissionHeaderCtrl from 'stores/Mission/view/MissionHeaderCtrl'
import CardLayout from 'components/CardLayout/CardLayout'
import MissInfo from 'pages/Mission/components/MissionInformation'
import MissInfoBaloise from 'pages/Mission/components/MissionInformationBaloise'
import ClaimInformation from 'pages/ClaimInformation'
import ClaimInformationBaloise from 'pages/ClaimInformation/indexBaloise'
import PolicyInformation from 'pages/PolicyInformation'
import PolicyInformationBaloise from 'pages/PolicyInformation/indexBaloise'
import MissionInformation from 'pages/MissionInformation'
import InformationBaloise from 'pages/MissionInformation/indexBaloise'
import ErrorBoundary from 'components/ErrorBoundary'
import Button from 'components/Button/Button'
import ClaimInformationSimplified from '../ClaimInformation/indexSimplified'
import PolicyInformationSimplified from '../PolicyInformation/indexSimplified'
import RequestMissionCreation from './Pages/MissionCreation/RequestMissionCreation'
import BlocNote from 'pages/Mission/pages/BlocNote/BlocNote'
import StickyNoteViewer from 'pages/Claim/Pages/StickyNoteViewer/StickyNoteViewer'
import CustomerConfigStore from '../../stores/Common/domain/CustomerConfigStore'
import { runInAction } from 'mobx'

class Claim extends React.Component {
  UNSAFE_componentWillMount() {
    const { id } = this.props.match.params
    this.props.ClaimStore.loadData(id)
  }
  customerConfigKey = 'ClaimInformationFormat' || 'TrackRecordInformationFormat'

  // In case that the api returns an error, the display will be done with default values
  defaultClaimInformationValues = 'default'

  constructor(props) {
    super(props)
    this.state = {
      values: this.defaultClaimInformationValues,
    }
  }

  async loadValuesFromCustomerConfig(key) {
    try {
      const customerId = this.props.UserStore.customer.id
      await CustomerConfigStore.loadCustomerConfigKey(customerId, key)
      runInAction(() => {
        this.setState({ values: CustomerConfigStore.getValue(key) })
      })
    } catch (err) {
      this.setState({ values: this.defaultClaimInformationValues })
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', MissionHeaderCtrl.handleKeyDown)
    this.loadValuesFromCustomerConfig(this.customerConfigKey)
  }

  render() {
    const {
      t,
      match: { path },
      ClaimStore: { loading, claim, isClaimClosed, id },
      UserStore: { isExpert, quickSwitchMode, hasDoubleRoleExpertAndInsurer, mode },
    } = this.props
    const { showExtDarvaBtn, checkExtranetDarva, loadingExtDarvaLink } = MissionHeaderCtrl
    if (hasDoubleRoleExpertAndInsurer && mode === 'expert') {
      quickSwitchMode('insurer')
    }

    if (loading) return <Loader />
    const customerComponentVariants = {
      simplified: {
        information: MissionInformation,
        claim: ClaimInformationSimplified,
        policy: PolicyInformationSimplified,
        summary: MissInfo,
      },
      Baloise: {
        information: InformationBaloise,
        claim: ClaimInformationBaloise,
        policy: PolicyInformationBaloise,
        summary: MissInfoBaloise,
      },
      default: {
        information: MissionInformation,
        claim: ClaimInformation,
        policy: PolicyInformation,
        summary: MissInfo,
      },
    }
    const { values } = this.state
    const customerComponentVariant =
      customerComponentVariants[values] || customerComponentVariants.default

    const Mission = props => {
      const { values, customerConfigKey } = props
      if (values === 'Baloise' || customerConfigKey === 'TrackRecordInformationFormat') {
        return <CurrentMissionBaloise {...props} />
      }
      return <CurrentMission {...props} />
    }

    return (
      <div className="claim-page container pl-0 pr-0">
        <div className="row d-none d-lg-flex align-items-center mb-3">
          <div className="col-3">
            <h5 className="title-side-nav mb-0">{t('claim.sideNav.title')}</h5>
          </div>

          <h5 className="col-5 sinister-title pr-0 mb-0">
            {t('mission.titleSinister')}
            &nbsp;
            <span>
              {claim.claimInformation.insurerClaimId}
              {showExtDarvaBtn && (
                <Button
                  className={classNames('btn btn-danger ml-2')}
                  onClick={() => checkExtranetDarva(id, 'cfi')}
                  loading={loadingExtDarvaLink}
                >
                  {t('mission.report.checkExtranetDarva')}
                </Button>
              )}
            </span>
          </h5>

          <div className="col-4">{<StatusSteps current={claim.status.key} />}</div>
        </div>

        <div className="row align-items-center align-items-lg-start">
          <div className="col-3 mb-3 mb-lg-0">
            <SideNav />
          </div>
          <div className="col-7 offset-2 mb-3 d-block d-lg-none">
            {<StatusSteps current={claim.status.key} />}
          </div>

          <div className="col-12 col-lg-9">
            {isExpert && MissionHeaderCtrl.headerInfo && (
              <CardLayout className="mission-info-header mb-3">
                {React.createElement(customerComponentVariant.summary)}
              </CardLayout>
            )}
            <ErrorBoundary>
              <Switch key="claim-pages">
                <Route exact path={`${path}/claim`} component={customerComponentVariant.claim} />
                <Route exact path={`${path}/policy`} component={customerComponentVariant.policy} />
                <Route
                  exact
                  path={`${path}/information`}
                  component={customerComponentVariant.information}
                />

                <Route exact path={`${path}/partyInvolved`} component={PartyInvolved} />
                <Route
                  exact
                  path={`${path}/SupportingDocuments`}
                  component={SupportingDocumentsPage}
                />

                <Route exact path={`${path}/events`} component={Events} />
                <Route exact path={`${path}/messaging`} component={Messaging} />

                <ProtectedRoute
                  condition={isClaimClosed}
                  redirectTo={`${path}/information`}
                  exact
                  path={`${path}/qualification`}
                  component={Qualification}
                />
                <ProtectedRoute
                  condition={isClaimClosed}
                  redirectTo={`${path}/information`}
                  exact
                  path={`${path}/planner`}
                  component={Planner}
                />
                <ProtectedRoute
                  condition={isClaimClosed}
                  redirectTo={`${path}/information`}
                  exact
                  path={`${path}/requestMissionCreationClaim`}
                  component={RequestMissionCreation}
                />
                <ProtectedRoute
                  condition={isClaimClosed}
                  redirectTo={`${path}/information`}
                  exact
                  path={`${path}/planner/overrun`}
                  component={Overrun}
                />
                <Route exact path={`${path}/blocNote`} component={BlocNote} />
                <Route exact path={`${path}/stickyNote`} component={StickyNoteViewer} />

                <Route
                  exact
                  path={`${path}/currentMission/:missionId`}
                  render={routeProps => (
                    <Mission
                      {...routeProps}
                      values={this.state.values}
                      customerConfigKey={this.customerConfigKey}
                    />
                  )}
                />

                <Route component={NotFound} />
              </Switch>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    )
  }
}

export default compose(PageLayout, inject('ClaimStore', 'UserStore'), withRouter, observer)(Claim)
