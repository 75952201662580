import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { path } from 'ramda'
import { compose } from 'recompose'

import { formatDate, concatAddress } from 'utils'
import Table from 'components/Table'
import CardLayout from 'components/CardLayout/CardLayout'
import FieldsBloc from 'components/FieldsBloc'
import Field from 'components/Field'
import InformationWorkCFA from './InformationWorkCFA'
import MissionModal from './MissionModal'
import MapCtrl from 'stores/Mission/view/MapCtrl'

const MissionInformation = ({
  t,
  ClaimStore: { claim: clm, loading, claimCorporel },
  UserStore: { isExpert },
  MissionStore: { isWorkCFA, cfa, showDelayButton, isIME, missionCorporel },
}) => {
  const [showModal, setShowModal] = useState(false)

  const claim = isExpert ? cfa.cfi : clm

  if (loading) return null

  if (isExpert && isWorkCFA) return <InformationWorkCFA />

  const addressLoss = concatAddress(path(['claimInformation', 'addressOfLoss'], claim))
  const missionInsurerInfoAddress = concatAddress(
    path(['mission', 'missionInsurerInformation', 'address'], claim),
  )
  const addressValue =
    addressLoss.toUpperCase() !== missionInsurerInfoAddress.toUpperCase()
      ? [addressLoss, missionInsurerInfoAddress]
      : addressLoss

  const stakeholdersConcernedTable = {
    fields: ['previousClaimId', 'previousDisasterDate', 'previousPaidIndemnity'],
    values: path(['claimInformation', 'previousClaim'], claim) || [], //TODO cambiar path cuando se cree en backend con info correspondiente
    headers: [
      t('mission.infoMission.thirdPartyName'),
      t('partyInvolved.modal.role'),
      t('mission.infoMission.accommodationLocation'),
    ],
    noValue: '-' /* t('common.notAvailable'), */,
  }
  return (
    <CardLayout className="claim">
      {showModal && <MissionModal {...{ showModal, setShowModal }} />}
      <Field
        name={t('mission.infoMission.type')}
        value={path(['claimInformation', 'coverage', 'value'], claim)}
      />
      <Field
        name={t('mission.claim.startingOriginDescription')}
        value={path(['claimInformation', 'startingOriginDescription'], claim)}
      ></Field>
      {path(['assignment', 'missionDate'], claim) && (
        <div className="row">
          <div className="col-4 col-sm-3 col-md-3 col-lg-3">
            {t('mission.infoMission.appointmentDate')}
          </div>
          <div className="col d-flex">
            <div className="flex-grow-1">
              {formatDate(path(['assignment', 'missionDate'], claim))}
            </div>
            {isExpert && showDelayButton && (
              <button className="btn btn-primary btn-sm" onClick={() => setShowModal(true)}>
                {t('mission.infoMission.modalDelay.buttonDelay')}
              </button>
            )}
          </div>
        </div>
      )}
      <Field
        name={t('mission.infoMission.expertiseDate')}
        value={formatDate(path(['assignment', 'expertiseDate'], claim))}
      />
      <Field
        name={t('mission.infoMission.dateIntegration')}
        value={formatDate(path(['mission', 'createdAt'], claim))}
      />
      <Field
        name={t('mission.infoMission.expertiseConcerns')} //TODO agregar path cuando se cree en backend
        value={path([''], claim) || '-'}
      />
      <Field
        name={t('mission.infoMission.contactName')}
        value={path(['matmutOutOfDarva', 'contactName'], claim)}
      />
      <Field
        name={t('mission.infoMission.contactEmail')}
        value={path(['matmutOutOfDarva', 'contactEmail'], claim)}
      />
      <Field
        name={t('mission.infoMission.contactPhoneNumber')}
        value={path(['matmutOutOfDarva', 'contactPhoneNumber'], claim)}
      />
      <Field
        name={t('mission.infoMission.expertiseAddress')}
        value={addressValue}
        className={Array.isArray(addressValue) ? 'crossout-first-li' : ''}
      />

      <Table
        title={t('mission.infoMission.stakeholdersConcerned')}
        {...stakeholdersConcernedTable}
        classNames="mx-4"
      />

      <FieldsBloc
        title={t('mission.infoMission.additionalInfos')}
        fields={[
          {
            name: t('mission.infoMission.investigationManagerRemarks'), //TODO agregar path cuando se cree en backend
            value: path([''], claim) || '-',
          },
        ]}
      />
    </CardLayout>
  )
}

export default compose(
  inject('ClaimStore', 'MissionStore', 'UserStore'),
  withTranslation(),
  observer,
)(MissionInformation, MapCtrl)
