import { observable, computed, action, decorate, runInAction } from 'mobx'
import { differenceInHours, addHours, addYears, format, startOfMonth, addDays } from 'date-fns'
import { isNil, path, type } from 'ramda'
import i18next from 'i18next'

import AlertCtrl from 'stores/Common/view/AlertCtrl'
import { fetchMission, getMissionsFromClaimId } from 'services/mission'
import { fetchClaim } from 'services/claim'
import { upsertAppointment, deleteAppointment } from 'services/calendar'
import { concatNames } from 'utils'
import { HORS_DARVA_TAGS } from 'stores/Common/domain/CommonStore'
import AppointmentCtrl from 'stores/Calendar/AppointmentCtrl'

class Appointement {
  loading = false
  loadingValidation = false
  loadingWans = false

  id = null
  title = ''
  start = null
  end = null
  type = ''
  address = null
  wan = ''
  comment = ''
  assigner = null
  assignee = null
  originalAssignee = null
  coverageKey = null
  previousStart = null
  previousEnd = null
  insurerClaimId = null
  insuredFirstName = null
  insuredLastName = null
  contactName = null
  wans = []
  every = 1
  isRecurrent = false
  repeatType = 'D'
  onDaysOfWeek = []
  monthSelectedOption = ''
  endRecurrentDate = null
  modifyRecurrencesSelectedOption = null
  appointmentRecurrentId = null
  horsDarva = false

  constructor(data) {
    if (data) {
      this.mergeData(data)
    }
  }

  get modifyReccurenceOptionIsSatify() {
    if (isNil(this.id) || isNil(this.appointmentRecurrentId) || !this.isRecurrent) return true
    return !isNil(this.modifyRecurrencesSelectedOption)
  }

  resetRecurrence = () => {
    const originData = this.originalData

    if (originData.isRecurrent) {
      this.isRecurrent = originData.isRecurrent || false
      this.every = originData.every || 1
      this.repeatType = originData.repeatType || 'D'
      this.onDaysOfWeek = originData.onDaysOfWeek || []
      this.endRecurrentDate = originData.endRecurrentDate
      if (originData.ordinal && originData.onDaysOfWeek.length > 0) {
        this.monthSelectedOption = `ordinal:${originData.ordinal}:${originData.onDaysOfWeek[0]}`
      }
    } else {
      this.isRecurrent = false
      this.every = 1
      this.repeatType = 'D'
      this.onDaysOfWeek = []
      this.monthSelectedOption = ''
      this.setEndRecurrenDate()
    }
  }

  setAppointmentType = value => {
    this.type = value
    if (value === 'MISS' || value === 'WAIT' || value === 'REMI') {
      this.isRecurrent = false
    }
    if (value === 'PERM') {
      this.title = 'Permanent'
    }
    if (value === 'VACA') {
      this.title = 'Absent'
    }
  }

  setIsRecurrent = value => {
    if (value === true) {
      this.resetRecurrence()
    }
    this.isRecurrent = value
  }

  setRepeatType = value => {
    this.repeatType = value
    this.setEndRecurrenDate()
  }

  setEndRecurrenDate = () => {
    if (this.start) {
      this.endRecurrentDate = addYears(this.start, this.repeatType === 'Y' ? 5 : 1)
    } else {
      this.endRecurrentDate = null
    }
  }

  get addressFormat() {
    if (!this.address) return null
    return {
      addressLine1: this.address.addressLine1,
      streetNumber: this.address.streetNumber,
      zipCode: this.address.zipCode,
      city: this.address.city,
      country: 'FR',
    }
  }

  get isValid() {
    const defaultValidation = this.title.length > 0 && this.type !== ''

    if (this.type === 'MISS' || this.type === 'WAIT' || this.type === 'REMI') {
      return defaultValidation && this.wan.length > 0
    }

    return defaultValidation
  }

  get asJson() {
    const appointment = {
      id: this.id,
      title: this.title,
      startDate: this.start,
      endDate: this.end,
      type: this.type,
      wan: this.wan.replace(/ /g, ''),
      comment: this.comment,
      assignee: this.assignee,
      isRecurrent: this.isRecurrent,
    }
    if (this.addressFormat) appointment.address = this.addressFormat
    if (this.isRecurrent) {
      appointment.every = this.every
      appointment.repeatType = this.repeatType
      appointment.onDaysOfWeek = this.onDaysOfWeek
      appointment.endRecurrentDate = this.endRecurrentDate
      appointment.startRecurrentDate = this.start
      appointment.modifyRecurrencesSelectedOption = this.modifyRecurrencesSelectedOption

      const monthSelectedOption = this.monthSelectedOption.split(':')
      if (this.repeatType === 'M' && monthSelectedOption[0] === 'ordinal') {
        appointment.ordinal = monthSelectedOption[1]
        appointment.onDaysOfWeek = [monthSelectedOption[2]]
      }
    }

    return appointment
  }

  get asEvent() {
    return {
      title: this.title,
      start: this.start,
      end: this.end,
      allDay: false,
      type: this.type,
      obj: this,
    }
  }

  get backgroundColor() {
    switch (this.type) {
      case 'REMI':
        return 'type-remi'
      case 'VACA':
        return 'type-holiday'
      case 'PERM':
        return 'type-perm'
      case 'MISS':
      case 'WAIT':
        return this.coverageKey ? `coverage--${this.coverageKey}` : 'type-no-color'
      default:
        return 'type-no-color'
    }
  }

  get wansForSelect() {
    return this.wans.map(({ wan, cfi }) => ({
      value: wan,
      label: concatNames(
        wan,
        '/',
        i18next.t('calendar.appointment.missionNum'),
        ':',
        path(['claimInformation', 'insurerMissionId'], cfi),
      ),
    }))
  }

  mergeData = data => {
    this.id = data.id || null
    this.title = data.title || ''
    this.start = data.start
    this.end = data.end
    this.type = data.type || ''
    this.address = data.address || null
    this.wan = data.wan || ''
    this.comment = data.comment || ''
    this.assigner = data.assigner || null
    this.assignee = data.assignee || null
    this.coverageKey = data.coverageKey || null
    this.previousStart = data.start || null
    this.previousEnd = data.end || null
    this.insurerClaimId = data.insurerClaimId || null
    this.insuredFirstName = data.insuredFirstName || null
    this.insuredLastName = data.insuredLastName || null
    this.contactName = data.contactName || null
    this.expertiseCharacteristic = data.expertiseCharacteristic || null
    this.expertiseType = data.expertiseType || null
    this.expertiseTypeKey = data.expertiseTypeKey || null
    this.missionNature = data.missionNature || null
    this.expertColor = data.expertColor || null
    this.originalAssignee = this.assignee
    this.isRecurrent = data.isRecurrent || false
    this.every = data.every || 1
    this.repeatType = data.repeatType || 'D'
    this.onDaysOfWeek = data.onDaysOfWeek || []
    this.appointmentRecurrentId = data.appointmentRecurrentId || null

    if (data.endRecurrentDate) {
      this.endRecurrentDate = data.endRecurrentDate
    } else {
      this.setEndRecurrenDate()
    }

    if (data.ordinal && data.onDaysOfWeek.length > 0) {
      this.monthSelectedOption = `ordinal:${data.ordinal}:${data.onDaysOfWeek[0]}`
    }

    // Only used for redirection to the the corresponding CFI wan if it's a manager/secretary (CFI)
    this.cfiWan = data.cfiWan || ''

    this.originalData = data
  }

  toggleOnDayOfWeek = day => {
    if (this.onDaysOfWeek.indexOf(day) === -1) {
      this.onDaysOfWeek = [...this.onDaysOfWeek, ...[day]]
    } else {
      this.onDaysOfWeek = this.onDaysOfWeek.filter(onDay => onDay !== day)
    }
  }

  get monthRepeatOptions() {
    if (this.repeatType !== 'M') {
      return []
    }

    const choices = []
    const startDate = this.start
    const dayOfMonth = format(startDate, 'dd')
    const dayName = format(startDate, 'EEEE')
    const ordinal = this.dayNameOrdinalOfMonth(startDate)
    choices.push({ key: `dayOfMonth:${dayOfMonth}`, type: 'day', dayOfMonth })
    choices.push({ key: `ordinal:${ordinal}:${dayName}`, type: 'ordinal', ordinal, dayName })
    return choices
  }

  dayNameOrdinalOfMonth = startDate => {
    let currentDateTmp = startOfMonth(startDate)
    let ordinal = 0

    while (currentDateTmp <= startDate) {
      currentDateTmp = addDays(currentDateTmp, 7)
      ordinal++
    }

    switch (ordinal) {
      case 1:
        return 'first'
      case 2:
        return 'second'
      case 3:
        return 'third'
      case 4:
        return 'fourth'
      default:
        return 'last'
    }
  }

  resetData() {
    this.mergeData(this.originalData)
  }

  isWAnValid = async (wan, isExpert, isInsurer) => {
    try {
      this.loadingValidation = true
      if (isExpert) await fetchMission(wan)
      else if (isInsurer) await fetchClaim(wan)
      else return false

      return true
    } catch (err) {
      return false
    } finally {
      runInAction(() => (this.loadingValidation = false))
    }
  }

  getWans = async id => {
    this.loadingWans = true
    try {
      const wans = await getMissionsFromClaimId(id)
      runInAction(() => {
        if (wans.length === 0) {
          this.address = null
          this.wan = ''
          AlertCtrl.alert('danger', 'calendar.appointment.fetchWanError')
        } else if (wans.length === 1) {
          this.wan = wans[0].wan
          this.address = path(['cfi', 'claimInformation', 'addressOfLoss'], wans[0])
          fetchMission(this.wan, true)
            .then(
              action(res => {
                const tags = path(['tags'], res)
                if (
                  !isNil(tags) &&
                  type(tags) === 'Array' &&
                  tags.some(tag => HORS_DARVA_TAGS.includes(tag))
                ) {
                  AppointmentCtrl.setHorsDarva(true)
                } else {
                  AppointmentCtrl.setHorsDarva(false)
                }
              }),
            )
            .catch(err => {
              console.log(err)
            })
        } else {
          this.wans = wans
        }
      })
    } catch (err) {
      runInAction(() => {
        this.wans = []
        this.address = null
        this.wan = ''
      })
    } finally {
      runInAction(() => (this.loadingWans = false))
    }
  }

  changeStartDate(value) {
    let difference = 1
    if (this.start && this.end) difference = differenceInHours(this.end, this.start)

    this.start = value
    this.end = addHours(value, difference)
    this.setEndRecurrenDate()
  }

  setProperty(key, value) {
    this[key] = value
  }

  async save() {
    this.loading = true
    try {
      const res = await upsertAppointment(this.asJson)
      runInAction(() => {
        this.previousStart = this.start
        this.previousEnd = this.end
      })
      return res
    } catch (err) {
      runInAction(() => {
        this.start = this.previousStart
        this.end = this.previousEnd
        this.assignee = this.originalAssignee
      })
      throw err
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  async destroy() {
    this.loading = true
    try {
      const res = await deleteAppointment(this.id, this.modifyRecurrencesSelectedOption)
      return res
    } catch (err) {
      throw err
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
}

const AppointementDecorated = decorate(Appointement, {
  title: observable,
  start: observable,
  end: observable,
  type: observable,
  address: observable,
  isRecurrent: observable,
  every: observable,
  repeatType: observable,
  onDaysOfWeek: observable,
  monthSelectedOption: observable,
  endRecurrentDate: observable,
  modifyRecurrencesSelectedOption: observable,
  appointmentRecurrentId: observable,
  horsDarva: observable,
  wan: observable,
  cfiWan: observable,
  comment: observable,
  assignee: observable,
  originalAssignee: observable,
  loading: observable,
  previousStart: observable,
  previousEnd: observable,
  loadingValidation: observable,
  insurerClaimId: observable,
  insuredFirstName: observable,
  insuredLastName: observable,
  contactName: observable,
  wans: observable,
  loadingWans: observable,
  expertColor: observable,
  originalData: observable,
  asJson: computed,
  asEvent: computed,
  backgroundColor: computed,
  addressFormat: computed,
  isValid: computed,
  wansForSelect: computed,
  monthRepeatOptions: computed,
  modifyReccurenceOptionIsSatify: computed,

  setProperty: action.bound,
  save: action.bound,
  destroy: action.bound,
  changeStartDate: action.bound,
  isWAnValid: action,
  getWans: action,
  mergeData: action,
  resetData: action,
  toggleOnDayOfWeek: action,
  resetRecurrence: action,
  setRepeatType: action,
  setIsRecurrent: action,
  setAppointmentType: action,
})

export default AppointementDecorated
