import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { runInAction } from 'mobx'

import PartyInvolvedDBStore from 'stores/Parameters/PartyInvolvedDBStore'
import PartyInvolvedCtrl from 'stores/Common/view/PartyInvolvedCtrl'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import RadioTabs from 'components/Forms/RadioTabs'
import Input from 'components/Forms/Input'
import TextArea from 'components/Forms/TextArea'
import Label from 'components/Label/Label'
import SelectRole from 'pages/PartyInvolved/SelectRole'
import SelectResponsability from 'pages/PartyInvolved/SelectResponsability'
import CompanyInfo from 'pages/PartyInvolved/CompanyInfo'
import InputCheckbox from 'components/Forms/InputCheckbox'
import PhoneNumber from 'components/Forms/PhoneNumber'
import Address from 'components/Address/Address'
import LastUpdatedAt from 'components/LastUpdatedAt'
import { concatAddress } from 'utils'
import SelectTypeOfVictim from './SelectTypeOfVictim'
import { path } from 'ramda'
import CustomerConfigStore from 'stores/Common/domain/CustomerConfigStore'

class Form extends React.Component {
  customerConfigKey = 'PersonalTitlesPicker'

  defaultMenuValues = ['Miss', 'Mister', 'Master', 'Missus']

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      values: this.defaultMenuValues,
    }
  }

  async loadValuesFromCustomerConfig(key) {
    try {
      this.setState({ loading: true })
      const customerId = this.props.UserStore.customer.id
      await CustomerConfigStore.loadCustomerConfigKey(customerId, key)

      runInAction(() => {
        const arrayValues = CustomerConfigStore.getValue(key)
        const newValues = []
        arrayValues.map(value => newValues.push(value))
        this.setState({ values: newValues, loading: false })
      })
    } catch (err) {
      this.setState({ values: this.defaultMenuValues, loading: false })
    }
  }

  componentDidMount() {
    this.loadValuesFromCustomerConfig(this.customerConfigKey)
  }

  render() {
    const {
      pageContext,
      party: {
        firstName,
        lastName,
        additionalInformation,
        typeOfVictimKey,
        civility,
        emailAddress,
        insuredBy,
        otherInsuredBy,
        associatedReference,
        streetNumber,
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        zipCode,
        partyType,
        errors,
        optOut,
        phoneNumber,
        mobilePhone,
        professionalPhone,
        subscriptionDate,
        identityCode,
        onSelectAddress,
        updatedAt,
        isUnknownAddress,
        setUnknownAddress,
      },
    } = PartyInvolvedCtrl
    const { partyInvolvedList } = PartyInvolvedDBStore
    const { t } = this.props
    const partyTypes = [
      { key: 'personal', value: t('partyInvolved.modal.personalType') },
      { key: 'business', value: t('partyInvolved.modal.businessType') },
    ]

    let civilityTypes = []

    Object.keys(this.state.values).forEach(element => {
      civilityTypes.push({
        value: this.state.values[element],
        label: t('partyInvolved.modal.' + this.state.values[element].toLocaleLowerCase()),
      })
    })

    const country = 'France'
    const countryIso = path(['country'], this.props.UserStore.customer) || 'FR'

    const handleError = (errors, name) => {
      const error = errors.find(error => error.key === name)
      if (error) return [error.value]
      return []
    }

    const onChange = e => PartyInvolvedCtrl.party.setProperty(e.target.name, e.target.value)
    const onChangePhone = (value, name) => PartyInvolvedCtrl.party.setProperty(name, value)
    const selectPartyInvolved = e => PartyInvolvedCtrl.setPartyInvolved(e.target.value)

    const insuredByOptions = [
      { value: 'Foyer', label: 'Foyer' },
      { value: 'Allianz', label: 'Allianz' },
      { value: 'Lalux', label: 'Lalux' },
      { value: 'Baloise', label: 'Baloise' },
      { value: 'AXA', label: 'AXA' },
      { value: 'Other', label: 'Other' },
    ]

    return (
      <div className="modal-body overflow-x-hidden">
        <form className="p-0">
          {pageContext === 'common' && (
            <div className="row">
              <div className="col-12 form-group">
                <ResponsiveSelect
                  name="searchText"
                  placeholder={t('partyInvolved.searchPartyInvolved')}
                  onChange={selectPartyInvolved}
                  isSearchable
                  options={partyInvolvedList}
                />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-12 form-group">
              <RadioTabs
                className="container pl-0 pr-0"
                name="type"
                options={partyTypes}
                value={partyType}
                onChange={onChange}
                tabSize="50%"
              />
            </div>
          </div>

          {typeOfVictimKey && (
            <div className="row">
              <div className="col-12 form-group">
                <Label required>{t('partyInvolved.modal.typeOfVictim')}</Label>
                <SelectTypeOfVictim required />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-12 col-sm-6 form-group">
              <Label required>{t('partyInvolved.modal.role')}</Label>
              <SelectRole required errors={handleError(errors, 'thirdPartyKey')} />
            </div>
            <div className="col-12 col-sm-6 form-group">
              <Label required>{t('partyInvolved.modal.responsability')}</Label>
              <SelectResponsability required />
            </div>
          </div>

          <div className="row">
            {subscriptionDate && (
              <div className="col-12 col-sm-6 form-group">
                <label>{t('partyInvolved.modal.subscriptionDate')}</label>
                <span className="form-control-plaintext">
                  {subscriptionDate.format(t('common.dateFormat'))}
                </span>
              </div>
            )}

            {identityCode !== null && (
              <div className="col-12 col-sm-6 form-group">
                <label>{t('partyInvolved.modal.identityCode')}</label>
                <span className="form-control-plaintext">{identityCode}</span>
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-12 col-sm-6 form-group">
              <Label required={partyType === 'personal'}>{t('partyInvolved.modal.civility')}</Label>
              <ResponsiveSelect
                required={partyType === 'personal'}
                name="civility"
                value={civility}
                options={civilityTypes}
                onChange={onChange}
                errors={handleError(errors, 'civility')}
              />
            </div>
            <div className="col-12 col-sm-6 form-group">
              <Label required={partyType === 'personal'}>{t('partyInvolved.modal.lastName')}</Label>
              <Input
                required={partyType === 'personal'}
                className="form-control"
                name="lastName"
                value={lastName}
                onChange={onChange}
                placeholder={t('partyInvolved.modal.lastName')}
                errors={handleError(errors, 'lastName')}
                maxLength={64}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-6 form-group">
              <Label required={partyType === 'personal'}>
                {t('partyInvolved.modal.firstName')}
              </Label>
              <Input
                required={partyType === 'personal'}
                className="form-control"
                name="firstName"
                value={firstName}
                onChange={onChange}
                placeholder={t('partyInvolved.modal.firstName')}
                errors={handleError(errors, 'firstName')}
                maxLength={64}
              />
            </div>
            <div className="col-12 col-sm-6 form-group">
              <label>{t('partyInvolved.modal.email')}</label>
              <Input
                className="form-control"
                name="emailAddress"
                value={emailAddress}
                onChange={onChange}
                placeholder={t('partyInvolved.modal.email')}
                errors={handleError(errors, 'emailAddress')}
                maxLength={64}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-6 form-group">
              <Label>{t('partyInvolved.modal.insuredBy')}</Label>
              <ResponsiveSelect
                name="insuredBy"
                value={insuredBy}
                options={insuredByOptions}
                onChange={onChange}
              />
            </div>
            <div className="col-12 col-sm-6 form-group">
              <Label>{t('partyInvolved.modal.associatedReference')}</Label>
              <input
                required={partyType === 'personal'}
                className="form-control"
                name="associatedReference"
                value={associatedReference}
                onChange={onChange}
                placeholder={t('partyInvolved.modal.associatedReference')}
                errors={handleError(errors, 'associatedReference')}
                maxLength={20}
              />
            </div>
            {insuredBy === 'Other' && (
              <div className="col-12 col-sm-6 form-group">
                <Label>{t('partyInvolved.modal.otherInsuredBy')}</Label>
                <input
                  className="form-control"
                  name="otherInsuredBy"
                  value={otherInsuredBy}
                  onChange={onChange}
                  placeholder={t('partyInvolved.modal.otherInsuredBy')}
                  maxLength={80}
                />
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-12 col-sm-4 form-group">
              <label>{t('partyInvolved.modal.phoneNumber')}</label>
              <PhoneNumber
                className="form-control"
                onChange={value => onChangePhone(value, 'phoneNumber')}
                placeholder={t('partyInvolved.modal.phoneNumber')}
                name="phoneNumber"
                errors={handleError(errors, 'phoneNumber')}
                value={phoneNumber}
                country={countryIso}
                indicateInvalid={false}
              />
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>{t('partyInvolved.modal.mobile')}</label>
              <PhoneNumber
                className="form-control"
                onChange={value => onChangePhone(value, 'mobilePhone')}
                placeholder={t('partyInvolved.modal.mobile')}
                name="phoneMobileNumber"
                errors={handleError(errors, 'mobilePhone')}
                value={mobilePhone}
                country={countryIso}
                indicateInvalid={false}
              />
            </div>
            <div className="col-12 col-sm-4 form-group">
              <label>{t('partyInvolved.modal.professionalPhone')}</label>
              <PhoneNumber
                className="form-control"
                onChange={value => onChangePhone(value, 'professionalPhone')}
                placeholder={t('partyInvolved.modal.professionalPhone')}
                name="professionalPhone"
                errors={handleError(errors, 'professionalPhone')}
                value={professionalPhone}
                country={countryIso}
                indicateInvalid={false}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 form-group">
              <label className="d-block">{t('partyInvolved.modal.address')}</label>

              <input
                id="unknownAddress"
                className="mr-2 clickable"
                type="checkbox"
                name="unknownAddress"
                onChange={setUnknownAddress}
                checked={isUnknownAddress}
              />
              <label className="clickable" htmlFor="unknownAddress">
                {t('partyInvolved.modal.unknownAddress')}
              </label>

              {!isUnknownAddress && (
                <Address
                  name="address"
                  onSelected={onSelectAddress}
                  country={countryIso}
                  initialValue={concatAddress({
                    streetNumber,
                    addressLine1,
                    addressLine2,
                    addressLine3,
                    city,
                    zipCode,
                    country,
                  })}
                  value={{
                    streetNumber,
                    addressLine1,
                    addressLine2,
                    addressLine3,
                    city,
                    zipCode,
                    country,
                  }}
                  errors={handleError(errors, 'zipCode')}
                />
              )}
            </div>
          </div>

          <CompanyInfo />

          <div className="row">
            <div className="col-12 form-group">
              <label>{t('partyInvolved.modal.additionalInfo')}</label>
              <TextArea
                className="form-control"
                name="additionalInformation"
                value={additionalInformation}
                onChange={onChange}
                errors={handleError(errors, 'additionalInformation')}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-12 text-right align-items-center">
              <span
                className="clickable mr-2"
                onClick={() => onChange({ target: { name: 'optOut', value: !optOut } })}
              >
                {t('partyInvolved.modal.optOutLegend')}
              </span>
              <InputCheckbox name="optOut" onChange={onChange} value={optOut} />
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <LastUpdatedAt lastUpdatedAt={updatedAt} saving={false} />
          </div>
        </form>
      </div>
    )
  }
}

export default compose(inject('UserStore'), withTranslation(), observer)(Form)
