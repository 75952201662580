import React, { useState, useEffect, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { path, isNil } from 'ramda'
import { Link } from 'react-router-dom'

import '../CurrentMission.css'
import { formatPhone, formatWan, formatDateHour, concatNames } from 'utils'
import CardLayout from 'components/CardLayout/CardLayout'
import Field from 'components/Field'
import Loader from 'components/Loader/Loader'
import AssigneeModal from '../AssigneeModal'
import Report from '../Report'
import MissionModal from 'pages/MissionInformation/MissionModal'
import SelectInvolvedPartyModal from 'pages/Mission/SelectInvolvedPartyModal'
import Button from 'components/Button/Button'

function CurrentMissionBaloise({
  ReportCtrl: { generateReport, getReportType, generateIRSIReport },
  ClaimStore: {
    loading,
    getMission,
    aggravating,
    lastMission,
    sendingSms,
    confirmSmsDate,
    sendConfirmationBySms,
    showSmsModal,
    setProperty,
    smsSelectedIP,
    phoneNumber,
    isHorsDarva,
    isClaimCorporel,
    claim: clm,
  },
  MissionStore,
  UserStore: { customer, hasDoubleRoleExpertAndInsurer, quickSwitchMode, isExpert },
  PartyInvolvedStore: { asSelectOptions, findMobilePhone },
}) {
  const { t } = useTranslation()
  const { id, missionId } = useParams()
  const history = useHistory()
  const mission = getMission(missionId)
  const claim = isExpert ? MissionStore.cfa.cfi : clm
  const [showDelayModal, setShowDelayModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [assignee, setAssignee] = useState({
    label: concatNames(
      path(['assigneeUser', 'lastName'], mission),
      path(['assigneeUser', 'firstName'], mission),
    ),
    value: path(['assigneeUser', 'id'], mission),
  })

  const smsReceiverNumber = path(['smsReceiverNumber'], mission)

  const isCurrentMissionClosed = path(['status', 'id'], mission) === 'CACC'
  const isCurrentMissionReportFiled = path(['status', 'id'], mission) === 'CARS'
  const isReportNotSent = !isCurrentMissionClosed && !isCurrentMissionReportFiled

  const isCurrentMissionLastMission = lastMission.id === mission.id

  const onChangeIP = e => {
    const phoneNumber = findMobilePhone(e.target.value)
    setProperty('phoneNumber', phoneNumber ? phoneNumber : '')
    setProperty('smsSelectedIP', e.target.value)
  }

  useEffect(() => {
    async function fetchData() {
      if (!isClaimCorporel) {
        await getReportType(missionId)
        await generateIRSIReport(missionId)
        await generateReport(missionId, isCurrentMissionClosed, missionId)
      }
    }
    fetchData()
  }, [
    getReportType,
    generateReport,
    generateIRSIReport,
    missionId,
    isCurrentMissionClosed,
    isClaimCorporel,
  ])

  if (loading || aggravating || isNil(mission)) {
    return (
      <CardLayout className="current-mission">
        <Loader />
      </CardLayout>
    )
  }

  return (
    <CardLayout className="current-mission">
      <div className="row">
        <div className="col-4 col-sm-3 col-md-3 col-lg-3">{t('claim.mission.missionNumber')}</div>
        <div className="col d-flex">
          <div className="flex-grow-1">{formatWan(missionId)}</div>
          {hasDoubleRoleExpertAndInsurer && (
            <div className="col-4">
              <button
                className="btn btn-primary btn-sm btn-block"
                onClick={() => {
                  quickSwitchMode('expert')
                  history.push(`/mission/${missionId}/missionInformation`)
                }}
              >
                {t('common.goTo')}
              </button>
            </div>
          )}
        </div>
      </div>

      {showModal && !isCurrentMissionClosed && (
        <AssigneeModal {...{ setAssignee, assignee, showModal, setShowModal }} />
      )}

      <MissionModal showModal={showDelayModal} setShowModal={setShowDelayModal} />

      <div className="row">
        <div className="col-4 col-sm-3 col-md-3 col-lg-3">{t('claim.mission.assignee')}</div>
        <div className="col d-flex">
          <div className="flex-grow-1">
            {concatNames(
              path(['assigneeUser', 'lastName'], mission),
              path(['assigneeUser', 'firstName'], mission),
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-4 col-sm-3 col-md-3 col-lg-3">{t('claim.mission.appointmentDate')}</div>
        <div className="col d-flex">
          <div className="flex-grow-1">
            {formatDateHour({ date: path(['appointmentDate'], mission) })}
            {smsReceiverNumber ? ` - Contact : ${formatPhone(smsReceiverNumber)} ` : ''}
            <Fragment>
              <SelectInvolvedPartyModal
                emailOptionAvailable={false}
                showModal={showSmsModal}
                onClose={() => {
                  setProperty('showSmsModal', false)
                  setProperty('phoneNumber', '')
                  setProperty('smsSelectedIP', null)
                }}
                onChange={e => setProperty(e.target.name, e.target.value)}
                onValid={() => sendConfirmationBySms(missionId)}
                onChangeIP={onChangeIP}
                optionsIP={asSelectOptions}
                selectedIP={smsSelectedIP}
                involvedPartyInputName="smsSelectedIP"
                phoneNumber={phoneNumber}
                loading={sendingSms}
              />
              <Button
                className="btn btn-primary"
                onClick={() => {
                  setProperty('showSmsModal', true)
                }}
                disabled={path(['smsSent'], mission)}
              >
                <i className="fa fa-commenting-o"></i>
              </Button>
            </Fragment>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-4 col-sm-3 col-md-3 col-lg-3">
          {t('mission.information.lastConfirmBySms')}
        </div>
        <div className="col d-flex">
          <div className="flex-grow-1">{formatDateHour({ date: confirmSmsDate })}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-4 col-sm-3 col-md-3 col-lg-3">{t('claim.mission.statut')}</div>
        <div className="col d-flex">
          <div className="flex-grow-1">{path(['status', 'value'], mission) || '-'}</div>
          <div className="col-4">
            <button
              className="btn btn-primary btn-sm btn-block"
              onClick={() => setShowDelayModal(true)}
            >
              {t('mission.infoMission.modalDelay.buttonDelay')}
            </button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-4 col-sm-3 col-md-3 col-lg-3">{t('mission.claim.guarantee')}</div>
        <div className="col d-flex">
          <div className="flex-grow-1">
            {path(['contract', 'guarantees[0]', 'guarantee', 'value'], claim) ||
              path(['contract', 'guarantees[0]', 'guaranteeStringName'], claim) ||
              '-'}
          </div>

          {!isHorsDarva && isReportNotSent && isCurrentMissionLastMission && (
            <div className="col-4">
              <Link
                className="btn btn-primary btn-sm btn-block"
                to={`/claim/${id}/planner/overrun`}
              >
                {t('claim.planner.overrun')}
              </Link>
            </div>
          )}
        </div>
      </div>

      {/*  <div className="row">
        <div className="col-4 col-sm-3 col-md-3 col-lg-3">{t('mission.claim.precisionGuarantee')}</div>
        <div className="col d-flex">
          <div className="flex-grow-1">
            {path(['contract', 'precisionGuarantee[0]', 'precisionGuarantee', 'value'], claim) || '-'}
          </div>
        </div>
      </div>  */}

      <div className="divider" />

      <Field
        name={t('claim.mission.missionBy')}
        value={`${path(['assignerUser', 'customer', 'name'], mission) || ''} - ${path(
          ['assignerUser', 'lastName'],
          mission,
        ) || ''} ${path(['assignerUser', 'firstName'], mission) || ''}`}
      />

      <Field
        name={t('claim.mission.phone')}
        value={formatPhone(path(['assignerUser', 'customer', 'phoneNumber'], mission))}
      />

      {!isClaimCorporel && <Report />}
    </CardLayout>
  )
}

export default inject(
  'ClaimStore',
  'ReportCtrl',
  'UserStore',
  'PartyInvolvedStore',
)(observer(CurrentMissionBaloise))
