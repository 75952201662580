import request from 'services/httpRequest'

export const fetchMission = async (wan, isQuery = false) => {
  const params = {
    'is_query': isQuery // if true, don't remove the NEW tag
  }
  const { data } = await request({
    method: 'get',
    url: `/claimFiles/v1/claimFileAssessor/${wan}`,
    params
  })

  return data.attributes
}

export const getMissionsFromClaimId = async id => {
  const params = {
    'filter[cfi.claimInformation.insurerClaimId]': id,
    'group[]': 'agenda',
  }
  const { data } = await request({
    method: 'get',
    url: `/claimFiles/v1/claimFileAssessor/`,
    params,
  })
  return data && data.map(item => item.attributes)
}

export const closeMission = async wan => {
  const { data } = await request({
    method: 'get',
    url: `/claimFiles/v1/claimFileAssessor/${wan}/close`,
  })
  return data.attributes
}

export const cancelMission = async (wan, attributes) => {
  try {
    const { data } = await request({
      method: 'POST',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/cancel`,
      data: {
        data: {
          type: 'cancel',
          attributes,
        },
      },
    })
    return data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getMotiveRetardOptions = async () => {
  const { data } = await request({ method: 'get', url: '/businessData/v1/motiveRetard' })
  return data
}

export const getObjectRetardOptions = async () => {
  const { data } = await request({ method: 'get', url: '/businessData/v1/objectRetard' })
  return data
}

export const fetchMotifCancelType = async () => {
  try {
    const { data } = await request({
      method: 'get',
      url: '/businessData/v1/cancellationClosure',
    })
    return data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const cfaSendCallback = async wan => {
  try {
    await request({
      method: 'get',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/callback`,
    })
  } catch (err) {
    console.log(err)
  }
}
